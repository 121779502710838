<script setup lang="ts">
const props = defineProps<{
  title?: string
  image: string
}>()
</script>

<template>
  <section
    class="relative min-h-96 bg-cover bg-center py-32"
    :style="{ backgroundImage: `url(${props.image})` }"
  >
    <div
      class="flex min-h-96 w-full items-center justify-center"
      v-if="props.title"
    >
      <UiBannerTitle>
        {{ props.title }}
      </UiBannerTitle>
    </div>
    <div class="z-10">
      <slot />
    </div>
  </section>
</template>
